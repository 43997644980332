<div class="footer-wrapper bg-black-primary">
  <div class="grid grid-nogutter border-bottom-1 border-purpurle-light p-0 pb-3 xl:pb-8 section-wrapper">
    <div class="col-12 flex flex-column sm:flex-row sm:align-items-center justify-content-between">
      <a routerLink="/">
        <img
          class="footer-logo cursor-pointer"
          ngSrc="assets/img/logo-footer.svg"
          height="70"
          width="70"
          alt="Image"
          loading="eager"
        />
      </a>
      <app-menu class="" [displayAuth]="false"></app-menu>
    </div>
  </div>
  <div class="section-wrapper flex justify-content-between align-items-center p-0 py-3 xl:pt-5 text-xs text-white">
    <span>&copy; 2023-{{currentYear}} Tattoon.ai, Inc. All rights reserved.</span>
    <div class="gap-4">
      <!--      <a href="#" class="social-icon">-->
      <!--        <i class="pi pi-twitter text-sm text-white"></i>-->
      <!--      </a>-->
      <!--      <a href="#" class="social-icon">-->
      <!--        <i class="icon icon-fb text-sm text-white"></i>-->
      <!--      </a>-->
      <!--      <a href="#" class="social-icon">-->
      <!--        <i class="pi pi-instagram text-sm text-white"></i>-->
      <!--      </a>-->
      <ul class="list-none p-0 m-0 pb-2 lg:pb-0 flex lg:align-items-center flex-column lg:flex-row cursor-pointer">
        <li>
          <a
            routerLink="/privacy-policy"
            routerLinkActive="text-pink-middle"
            pRipple
            class="flex align-items-center px-0 py-0 lg:px-5 hover:text-pink-middle text-white font-medium transition-colors transition-duration-150"
            style="height: 41px"
          >
            Privacy policy
          </a>
        </li>
        <li>
          <a
            routerLink="/terms-of-use"
            routerLinkActive="text-pink-middle"
            pRipple
            class="flex align-items-center px-0 lg:px-5 hover:text-pink-middle text-white font-medium transition-colors transition-duration-150"
            style="height: 41px"
          >
            Terms of use
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
