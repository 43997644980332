export const environment = {
  firebase: {
    apiKey: 'AIzaSyA0h0HpI8PTnQILkiNFCvUu1FLqFvsKTzg',
    authDomain: 'zerolimit-tattoo.firebaseapp.com',
    projectId: 'zerolimit-tattoo',
    storageBucket: 'zerolimit-tattoo.appspot.com',
    messagingSenderId: '231062469912',
    appId: '1:231062469912:web:e47c0010066c45b892326d',
  },
  BASE_URL: 'https://api2.tattooai.ink/v1',
  crispKey: '',
  website_url: 'https://tattooai.ink',
  production: false,
  devMode: true,
  local: false,
  stripe: {
    publicKey:
      'pk_test_51Nim56J8QELD5U2A4bt3d64PXwwosCkh3hplEzqn8805aNhrAw4Hvp39a88xxCZEdD4ncLYKVkG7tZ94LI2pnSXW00ST65qXxT',
  },
  heapAnalytics: {
    id: '4121803457',
  },
};
