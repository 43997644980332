import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '../../../environments/environment';
import { isPlatformBrowser } from '@angular/common';

declare let heap: any;

@Injectable({
  providedIn: 'root',
})
export class HeapAnalyticsService {
  private platformId = inject(PLATFORM_ID);

  track(event: string, properties?: Record<string, any>): void {
    if (typeof heap !== 'undefined') {
      heap.track(event, properties);
    } else {
      console.warn('Heap is not initialized.');
    }
  }

  identify(userId: string, properties?: Record<string, any>): void {
    if (typeof heap !== 'undefined') {
      heap.identify(userId);
      if (properties) {
        heap.addUserProperties(properties);
      }
    } else {
      console.warn('Heap is not initialized.');
    }
  }

  addUserProperties(properties: Record<string, any>): void {
    if (typeof heap !== 'undefined') {
      heap.addUserProperties(properties);
    } else {
      console.warn('Heap is not initialized.');
    }
  }

  loadHeapScript() {
    if (!isPlatformBrowser(this.platformId)) return;
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.text = `
      (window.heapReadyCb = window.heapReadyCb || []),
      (window.heap = window.heap || []),
      (heap.load = function (e, t) {
        (window.heap.envId = e),
          (window.heap.clientConfig = t = t || {}),
          (window.heap.clientConfig.shouldFetchServerConfig = !1);
        var a = document.createElement('script');
        (a.type = 'text/javascript'),
          (a.async = !0),
          (a.src = 'https://cdn.us.heap-api.com/config/' + e + '/heap_config.js');
        var r = document.getElementsByTagName('script')[0];
        r.parentNode.insertBefore(a, r);
        var n = [
            'init',
            'startTracking',
            'stopTracking',
            'track',
            'resetIdentity',
            'identify',
            'getSessionId',
            'getUserId',
            'getIdentity',
            'addUserProperties',
            'addEventProperties',
            'removeEventProperty',
            'clearEventProperties',
            'addAccountProperties',
            'addAdapter',
            'addTransformer',
            'addTransformerFn',
            'onReady',
            'addPageviewProperties',
            'removePageviewProperty',
            'clearPageviewProperties',
            'trackPageview',
          ],
          i = function (e) {
            return function () {
              var t = Array.prototype.slice.call(arguments, 0);
              window.heapReadyCb.push({
                name: e,
                fn: function () {
                  heap[e] && heap[e].apply(heap, t);
                },
              });
            };
          };
        for (var p = 0; p < n.length; p++) heap[n[p]] = i(n[p]);
      });
      heap.load('${environment.heapAnalytics.id}');
    `;
    document.head.appendChild(script);
  }
}
