import { Component } from '@angular/core';
import { MenuComponent } from '../menu/menu.component';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { RippleModule } from 'primeng/ripple';
import { NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [MenuComponent, RouterLink, RouterLinkActive, RippleModule, NgOptimizedImage],
})
export class FooterComponent {
  currentYear = new Date().getFullYear();
}
